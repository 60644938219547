import { Roles } from '../constants/roles';
import {
  MdAccountCircle,
  MdGroupAdd,
  MdReceipt,
  MdBusiness,
  MdAttachMoney,
  MdFormatListBulleted,
  MdSettings,
  MdShoppingCart,
  MdGridOn,
  MdShare,
  MdHistory,
  MdPeopleOutline,
  MdAccountBalance,
} from 'react-icons/md';
import { FaUserAlt, FaUserFriends } from 'react-icons/fa';
import { ApiResources } from 'constants/apiResources';
import { PermissionActions } from 'constants/permissionActions';
import { CustomCompanies } from 'constants/customCompanies';

export const navAdminItems = [
  {
    to: '/users',
    name: 'Users',
    exact: false,
    Icon: MdAccountCircle,
    roles: [Roles.DmsAdmin, Roles.CompanyAdmin],
    childrenRoutes: ['/user-new', '/user-detail'],
  },
  {
    to: '/roles',
    name: 'Roles',
    exact: false,
    Icon: MdGroupAdd,
    roles: [Roles.DmsAdmin],
  },
  {
    to: '/permissions',
    name: 'Permissions',
    exact: false,
    Icon: MdPeopleOutline,
    roles: [Roles.DmsAdmin, Roles.CompanyAdmin],
  },
  {
    to: '/customer-group-permissions',
    name: 'Customer Permissions',
    exact: false,
    Icon: MdPeopleOutline,
    roles: [Roles.DmsAdmin],
  },
  {
    to: '/logs',
    name: 'Logs',
    exact: false,
    Icon: MdSettings,
    roles: [Roles.DmsAdmin],
    childrenRoutes: ['/log-detail'],
  },
];

export const navGeneralItems = [
  {
    to: '/company-list',
    name: 'Company',
    exact: false,
    Icon: MdBusiness,
    resource: ApiResources.Company,
    action: PermissionActions.View,
    childrenRoutes: ['/company-new', '/company-edit', '/company-detail'],
  },
  {
    to: '/setting-list',
    name: 'Setting',
    exact: false,
    Icon: MdSettings,
    resource: ApiResources.Setting,
    action: PermissionActions.View,
    childrenRoutes: ['/setting-new', '/setting-edit'],
  },
  {
    to: '/currency-list',
    name: 'Currency',
    exact: false,
    Icon: MdAttachMoney,
    resource: ApiResources.Currency,
    action: PermissionActions.View,
    childrenRoutes: ['/currency-new', '/currency-edit'],
  },
  {
    to: '/country-list',
    name: 'Country',
    exact: false,
    Icon: MdSettings,
    resource: ApiResources.Country,
    action: PermissionActions.View,
    childrenRoutes: ['/country-new', '/country-edit'],
  },
  {
    to: '/bank-list',
    name: 'Bank',
    exact: false,
    Icon: MdAccountBalance,
    resource: ApiResources.Bank,
    action: PermissionActions.View,
    childrenRoutes: ['/bank-new', '/bank-edit'],
  },
  {
    to: '/uom-list',
    name: 'UOM',
    exact: false,
    Icon: MdReceipt,
    resource: ApiResources.UOM,
    action: PermissionActions.View,
    childrenRoutes: ['/uom-new', '/uom-edit'],
  },
  {
    to: '/fcm-message',
    name: 'Notification Message',
    exact: false,
    Icon: MdSettings,
    resource: ApiResources.Notification,
    action: PermissionActions.View,
  },
];

export const navGeneralTaxItems = [
  {
    to: '/tax-code-list',
    name: 'Tax code',
    exact: false,
    Icon: MdBusiness,
    resource: ApiResources.TaxCode,
    action: PermissionActions.View,
    childrenRoutes: ['/tax-code-new', '/tax-code-edit'],
  },
  {
    to: '/tax-group-list',
    name: 'Tax Group',
    exact: false,
    Icon: MdAttachMoney,
    resource: ApiResources.TaxGroup,
    action: PermissionActions.View,
    childrenRoutes: ['/tax-group-new', '/tax-group-edit'],
  },
  {
    to: '/item-tax-group-list',
    name: 'Item Tax Group',
    exact: false,
    Icon: MdReceipt,
    resource: ApiResources.ItemTaxGroup,
    action: PermissionActions.View,
    childrenRoutes: ['/item-tax-group-new', '/item-tax-group-edit'],
  },
];

export const navARItems = [
  {
    to: '/received-order-list',
    name: 'Received Orders',
    exact: true,
    Icon: MdShoppingCart,
    resource: ApiResources.PurchaseOrder,
    action: PermissionActions.ViewReceivedOrders,
  },
  {
    to: '/sale-order-list',
    name: 'Sale Orders',
    exact: true,
    Icon: MdShoppingCart,
    resource: ApiResources.SaleOrder,
    action: PermissionActions.View,
    childrenRoutes: ['/sale-order-new', '/sale-order-detail'],
  },
  {
    to: '/transaction-list',
    name: 'Transaction',
    exact: true,
    Icon: MdAttachMoney,
    resource: ApiResources.Transaction,
    action: PermissionActions.View,
  },
  {
    to: '/invoice-list',
    name: 'Invoice',
    exact: true,
    Icon: MdReceipt,
    resource: ApiResources.Invoice,
    action: PermissionActions.View,
    alterName: "Payment",
    alterNameOnCompany: CustomCompanies.MyAc,
    childrenRoutes: ['/invoice-new', '/invoice-edit'],
  },
  {
    to: '/invoice-cancelled-list',
    name: 'Invoice Cancelled',
    exact: true,
    Icon: MdReceipt,
    resource: ApiResources.InvoiceCancelled,
    action: PermissionActions.View,
    alterName: "InvoiceCancelled",
    childrenRoutes: ['/invoice-cancelled-edit'],
  },
  {
    to: '/invoice-detail',
    name: 'Order Details',
    exact: true,
    Icon: MdReceipt,
    resource: ApiResources.InvoiceDetail,
    action: PermissionActions.View,
  },
  {
    to: '/wallet-list',
    name: 'Wallet',
    exact: false,
    Icon: MdFormatListBulleted,
    resource: ApiResources.Wallet,
    action: PermissionActions.View,
    childrenRoutes: ['/wallet-edit'],
  },
  {
    to: '/wallet-transaction-list',
    name: 'Wallet Transaction',
    exact: false,
    Icon: MdFormatListBulleted,
    resource: ApiResources.WalletTransaction,
    action: PermissionActions.View,
  },
  {
    to: '/payment-term-list',
    name: 'Payment Term',
    exact: false,
    Icon: MdFormatListBulleted,
    resource: ApiResources.PaymentTerm,
    action: PermissionActions.View,
    childrenRoutes: ['/payment-term-new', '/payment-term-edit'],
  },
  {
    to: '/frewie-platform-list',
    name: 'Frewie Platform',
    exact: false,
    Icon: MdFormatListBulleted,
    resource: ApiResources.FrewiePlatforms,
    action: PermissionActions.View,
    childrenRoutes: ['/frewie-platform-new', '/frewie-platform-edit'],
  },
  {
    to: '/frewie-coupon-list',
    name: 'Coupon',
    exact: false,
    Icon: MdFormatListBulleted,
    resource: ApiResources.Coupons,
    action: PermissionActions.View,
    childrenRoutes: ['/frewie-coupon-new', '/frewie-coupon-edit'],
  },
  {
    to: '/purchase-setup-list',
    name: 'Sequence Setup',
    exact: false,
    Icon: MdSettings,
    resource: ApiResources.PurchaseSetup,
    action: PermissionActions.View,
  },
];

export const navCustomerItems = [
  {
    to: '/customer-list',
    name: 'Customer',
    exact: false,
    Icon: FaUserAlt,
    resource: ApiResources.Customer,
    action: PermissionActions.View,
    childrenRoutes: ['/customer-new', '/customer-edit'],
  },
  {
    to: '/customer-group-list',
    name: 'Customer Group',
    exact: false,
    Icon: FaUserFriends,
    resource: ApiResources.CustomerGroup,
    action: PermissionActions.View,
    childrenRoutes: ['/customer-group-new', '/customer-group-edit'],
  },
  {
    to: '/consumer-list',
    name: 'Consumer',
    exact: false,
    Icon: FaUserAlt,
    resource: ApiResources.Consumer,
    action: PermissionActions.View,
    childrenRoutes: ['/consumer-edit'],
  },
  {
    to: '/consumer-group-list',
    name: 'Consumer Group',
    exact: false,
    Icon: FaUserFriends,
    resource: ApiResources.ConsumerGroup,
    action: PermissionActions.View,
    childrenRoutes: ['/consumer-group-new', '/consumer-group-edit'],
  },
  {
    to: '/consumer-affiliate-registration-list',
    name: 'Consumer Affiliate Registration',
    exact: false,
    Icon: FaUserFriends,
    resource: ApiResources.ConsumerAffiliateRegistration,
    action: PermissionActions.View,
    childrenRoutes: ['/consumer-affiliate-registration-edit'],
  },
  {
    to: '/usage-notification-list',
    name: 'Usage Notification',
    exact: false,
    Icon: FaUserFriends,
    resource: ApiResources.UsageNotification,
    action: PermissionActions.View,
    childrenRoutes: ['/usage-notification-edit'],
  },
  {
    to: '/consumer-commission-list',
    name: 'Consumer Commission',
    exact: false,
    Icon: MdFormatListBulleted,
    resource: ApiResources.ConsumerCommission,
    action: PermissionActions.View,
  },
  {
    to: '/consumer-affiliate-list',
    name: 'Consumer Affiliate',
    exact: false,
    Icon: MdFormatListBulleted,
    resource: ApiResources.ConsumerAffiliate,
    action: PermissionActions.View,
  },
  {
    to: '/frewie-coupon-transaction-list',
    name: 'Coupon Transactions',
    exact: false,
    Icon: MdFormatListBulleted,
    resource: ApiResources.CouponTransactions,
    action: PermissionActions.View,
    childrenRoutes: [],
  },
  {
    to: '/membership-list',
    name: 'Membership',
    exact: false,
    Icon: MdSettings,
    resource: ApiResources.Membership,
    action: PermissionActions.View,
    childrenRoutes: ['/membership-new', '/membership-edit'],
  },
  {
    to: '/service-moving-journal-list',
    name: 'Service Moving Journal',
    exact: false,
    Icon: MdHistory,
    resource: ApiResources.ServiceMovingJournal,
    action: PermissionActions.View,
    childrenRoutes: ['/service-moving-journal-new', '/service-moving-journal-edit'],
  },
];

export const navAppointmentItems = [
  {
    to: '/appointment-list',
    name: 'Appointment',
    exact: false,
    Icon: MdSettings,
    resource: ApiResources.Appointment,
    action: PermissionActions.View,
    childrenRoutes: ['/appointment-new', '/appointment-edit'],
  },
];

export const navAppointmentSetupItems = [
  {
    to: '/timeslot-list',
    name: 'Timeslot',
    exact: false,
    Icon: MdSettings,
    resource: ApiResources.Timeslot,
    action: PermissionActions.View,
    childrenRoutes: ['/timeslot-new', '/timeslot-edit'],
  },
  {
    to: '/holiday-list',
    name: 'Holiday',
    exact: false,
    Icon: MdSettings,
    resource: ApiResources.Holiday,
    action: PermissionActions.View,
    childrenRoutes: ['/holiday-new', '/holiday-edit'],
  },
  {
    to: '/appointment-config',
    name: 'Appointment Config',
    exact: false,
    Icon: MdSettings,
    resource: ApiResources.Appointment,
    action: PermissionActions.View,
  },
];

export const navProductItems = [
  {
    to: '/product-list',
    name: 'Product',
    exact: false,
    Icon: MdGroupAdd,
    resource: ApiResources.Product,
    action: PermissionActions.View,
    childrenRoutes: ['/product-new', '/product-edit', '/product-detail'],
  },
  {
    to: '/movement-journal-list',
    name: 'Movement Journal',
    exact: false,
    Icon: MdGroupAdd,
    resource: ApiResources.MovingJournal,
    action: PermissionActions.View,
    childrenRoutes: ['/movement-journal-new', '/movement-journal-adjust', '/movement-journal-detail'],
  },
];

export const navProductSetupItems = [
  {
    to: '/cost-type-setup-list',
    name: 'Cost Type Setup',
    exact: false,
    Icon: MdAccountCircle,
    resource: ApiResources.CostTypeSetup,
    action: PermissionActions.View,
    childrenRoutes: ['/cost-type-setup-new', '/cost-type-setup-edit'],
  },
  {
    to: '/cost-setup-list',
    name: 'Cost Setup',
    exact: false,
    Icon: MdGroupAdd,
    resource: ApiResources.CostSetup,
    action: PermissionActions.View,
    childrenRoutes: ['/cost-setup-new', '/cost-setup-edit'],
  },
  {
    to: '/warranty-type-list',
    name: 'Warranty Type',
    exact: false,
    Icon: MdGroupAdd,
    resource: ApiResources.WarrantyType,
    action: PermissionActions.View,
    childrenRoutes: ['/warranty-type-new', '/warranty-type-edit'],
  },
  {
    to: '/warranty-list',
    name: 'Warranty',
    exact: false,
    Icon: MdGroupAdd,
    resource: ApiResources.Warranty,
    action: PermissionActions.View,
    childrenRoutes: ['/warranty-new', '/warranty-edit'],
  },
  {
    to: '/dimension-setup-list',
    name: 'Dimension Setup',
    exact: false,
    Icon: MdGroupAdd,
    resource: ApiResources.DimensionSetup,
    action: PermissionActions.View,
    childrenRoutes: ['/dimension-setup-new', '/dimension-setup-edit'],
  },
  {
    to: '/location-setup',
    name: 'Location Setup',
    exact: false,
    Icon: MdGroupAdd,
    resource: ApiResources.LocationSetup,
    action: PermissionActions.View,
  },
  {
    to: '/location-unit-list',
    name: 'Location Unit',
    exact: false,
    Icon: MdGroupAdd,
    resource: ApiResources.LocationUnit,
    action: PermissionActions.View,
    childrenRoutes: ['/location-unit-new', '/location-unit-edit'],
  },
  {
    to: '/location-sorting-list',
    name: 'Location Sorting',
    exact: false,
    Icon: MdGroupAdd,
    resource: ApiResources.LocationSorting,
    action: PermissionActions.View,
    childrenRoutes: ['/location-sorting-new', '/location-sorting-edit'],
  },
  {
    to: '/product-category-list',
    name: 'Product Category',
    exact: false,
    Icon: MdGroupAdd,
    resource: ApiResources.ProductCategory,
    action: PermissionActions.View,
    childrenRoutes: ['/product-category-new', '/product-category-edit'],
  },
  {
    to: '/product-type-list',
    name: 'Product Type',
    exact: false,
    Icon: MdGroupAdd,
    resource: ApiResources.ProductType,
    action: PermissionActions.View,
    childrenRoutes: ['/product-type-new', '/product-type-edit'],
  },
  {
    to: '/frewie-plan-list',
    name: 'Product Plan',
    exact: false,
    Icon: MdGroupAdd,
    resource: ApiResources.FrewieProductPlan,
    action: PermissionActions.View,
    childrenRoutes: ['/frewie-plan-new', '/frewie-plan-edit'],
  },
  {
    to: '/frewie-serial-number-list',
    name: 'Serial Number',
    exact: false,
    Icon: MdGroupAdd,
    resource: ApiResources.FrewieSerialNumber,
    action: PermissionActions.View,
    childrenRoutes: ['/frewie-serial-number-new', '/frewie-serial-number-edit'],
  },
  {
    to: '/spark-package-import',
    name: 'Spark Package Plan Import',
    exact: false,
    Icon: MdGroupAdd,
    resource: ApiResources.FrewieProductPlan,
    action: PermissionActions.View,
  },
  {
    to: '/location-list',
    name: 'Location',
    exact: false,
    Icon: MdGroupAdd,
    resource: ApiResources.Location,
    action: PermissionActions.View,
  },
  {
    to: '/brand-list',
    name: 'Brand',
    exact: false,
    Icon: MdGroupAdd,
    resource: ApiResources.Brand,
    action: PermissionActions.View,
    childrenRoutes: ['/brand-new', '/brand-edit'],
  },
];

export const navAgentItems = [
  {
    to: '/agent-list',
    name: 'Downline',
    exact: false,
    Icon: MdGridOn,
    resource: ApiResources.Agent,
    action: PermissionActions.View,
    childrenRoutes: ['/agent-new', '/agent-edit'],
  },
  {
    to: '/agent-hierarchy',
    name: 'Hierarchy',
    exact: false,
    Icon: MdShare,
    resource: ApiResources.Agent,
    action: PermissionActions.View,
  },
];
